import { forwardRef, useImperativeHandle } from 'react';
import { Container, Box, Paper, List, ListItem, ListItemAvatar, ListItemText, Grow } from '@mui/material';
import CelebrationIcon from '@mui/icons-material/Celebration';

import TrainBullet from './TrainBullet';

const Delays = forwardRef((props, ref) => {
  const { delays, trains } = props;

  const pageScroll = () => {
    const interval =  75;
    window.scrollBy(0, 10); // horizontal and vertical scroll increments
    let scrolldelay = setTimeout(pageScroll, interval);
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
      clearTimeout(scrolldelay);
    }
  }

  useImperativeHandle(ref, () => ({
    scroll() {
      setTimeout(pageScroll, 1000);
    }
  }));
  return (
    <Container maxWidth="xl">
      <Grow in>
      <Box sx={{ display: 'flex', flexGrow: 1, flexAlign: 'flex-start'}}>
        <Paper elevation={10} sx={{ width: '100%', my: 2, display: 'inline-block' }}>
          <List>
            {
              Object.keys(delays).map((trainId) => {
                return (
                  <ListItem divider key={trainId}>
                    <ListItemAvatar sx={{ mr: 2 }}>
                      <TrainBullet id={trainId} trains={trains} />
                    </ListItemAvatar>
                    <ListItemText>
                      { delays[trainId].map((delayText) => (
                          <p>{ delayText.replace(/ - /g, "–") }</p>
                        ))
                      }
                    </ListItemText>
                  </ListItem>
                )
              })
            }
            {
              Object.keys(delays).length === 0 &&
              <ListItem>
                <ListItemText>
                  No delays detected, hooray! <CelebrationIcon />
                </ListItemText>
              </ListItem>
            }
          </List>
        </Paper>
      </Box>
      </Grow>
    </Container>
  )
});

export default Delays;
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import TrainBullet from './TrainBullet';

const DeleteStationDialog = (props) => {
  const { selectedStations, stations, open, handleClose, handleRemoveStation, trains } = props;

  const handleOnDelete = (event) => {
    handleRemoveStation(event.currentTarget.dataset.stationid);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Remove Station</DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <List sx={{ width: '100%'}}>
            {
              selectedStations.map((stationId) => {
                const station = stations[stationId];
                return (
                  <ListItem
                    key={stationId}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" data-stationId={stationId} onClick={handleOnDelete}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={station.name.replace(/ - /g, "–")}
                      secondary={station.secondary_name || '\xa0'}
                    />
                    <Box sx={{ml: 1}}>
                    {
                      Object.keys(station.routes).map((trainId) => (
                        <TrainBullet id={trainId} key={trainId} trains={trains} size='small' />
                      ))
                    }
                    </Box>
                  </ListItem>
                )
              })
            }
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteStationDialog;
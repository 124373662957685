import { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import RailwayAlertIcon from '@mui/icons-material/RailwayAlert';
import WarningIcon from '@mui/icons-material/Warning';
import Paper from '@mui/material/Paper';

import DepartureTimes from './components/DepartureTimes';
import Delays from './components/Delays';
import ServiceChanges from './components/ServiceChanges';

import { useInterval } from './utils';
import { loadSelectedStationsFromLocalStorage, saveSelectedStationsToLocalStorage } from './localStorage';

import './App.css';

const stopsUrl = 'https://www.goodservice.io/api/stops/';
const routesUrl = "https://goodservice.io/api/routes/?detailed=1"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontSize: 17,
  },
});

const parseDelays = (routes) => {
  const delaysData = {};
  Object.keys(routes).forEach((routeId) => {
    const route = routes[routeId];
    if (route.status === 'Delay') {
      const value = [route.delay_summaries.north, route.delay_summaries.south].filter((s) => s)
      delaysData[routeId] = value;
    }
  });
  return delaysData;
}

const parseServiceChanges = (routes) => {
  const serviceChangesData = {};
  Object.keys(routes).forEach((routeId) => {
    const route = routes[routeId];
    if (route.service_change_summaries) {
      const value = [
        route.service_change_summaries.both,
        route.service_change_summaries.north,
        route.service_change_summaries.south,
      ].flat(1).filter((s) => s)
      if (value.length > 0) {
        serviceChangesData[routeId] = value;
      }
    }
  });
  return serviceChangesData;
}

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const [stations, setStations] = useState({});
  const [stationsList, setStationsList] = useState([]);
  const [trains, setTrains] = useState({});
  const [selectedNav, setSelectedNav] = useState(0);
  const [delays, setDelays] = useState({});
  const [serviceChanges, setServiceChanges] = useState({});
  const [selectedStations, setSelectedStations] = useState(() => {
    const loaded = loadSelectedStationsFromLocalStorage();

    return loaded || [];
  });
  const departureTimesRef = useRef();
  const delaysRef = useRef();
  const serviceChangesRef = useRef();
  let t;

  const updateData = () => {
    fetch(stopsUrl)
      .then(response => response.json())
      .then(data => {
        const stationsData = {};
        data.stops.forEach((stop) => {
          stationsData[stop.id] = stop;
        });
        setStations(stationsData);
        setStationsList(data.stops);
        fetch(routesUrl)
          .then(response => response.json())
          .then(data => {
            setTrains(data.routes);
            setDelays(parseDelays(data.routes));
            setServiceChanges(parseServiceChanges(data.routes));
            setIsLoading(false);
          });
      });
  }

  const kioskMode = () => {
    if (!isIdle) {
      return;
    }

    if (selectedNav === 0) {
      if (!departureTimesRef.current.goNext()) {
        setSelectedNav(1);
        setTimeout(() => delaysRef.current.scroll(), 100);
      }
    } else if (selectedNav === 1) {
      setSelectedNav(2);
    } else if (selectedNav === 2) {
      if (!serviceChangesRef.current.goNext()) {
        setSelectedNav(0);
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    updateData();
    handleResetTimer();
  }, []);
  useInterval(updateData, 15000);
  useInterval(kioskMode, 5000);

  const handleNavChange = (event, newValue) => {
    setSelectedNav(newValue);
  }

  const handleAddStation = (station) => {
    const stationsArray = selectedStations;
    stationsArray.push(station);
    setSelectedStations(stationsArray);
    saveSelectedStationsToLocalStorage(stationsArray);
  }

  const handleRemoveStation = (station) => {
    const stationsArray = selectedStations.filter((s) => s !== station);
    setSelectedStations(stationsArray);
    saveSelectedStationsToLocalStorage(stationsArray);
  }

  const handleIdle = () => {
    setIsIdle(true);
  }

  const handleResetTimer = () => {
    setIsIdle(false);
    clearTimeout(t);
    t = setTimeout(handleIdle, 10000);
  }

  window.onclick = handleResetTimer;

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ pb: 7 }}>
        <CssBaseline />
        { !isLoading && selectedNav === 0 &&
            <DepartureTimes
              selectedStations={selectedStations}
              stations={stations}
              stationsList={stationsList}
              trains={trains}
              handleAddStation={handleAddStation}
              handleRemoveStation={handleRemoveStation}
              ref={departureTimesRef}
            />
        }
        { !isLoading && selectedNav === 1 &&
          <Delays delays={delays} trains={trains} ref={delaysRef} /> }
        { !isLoading && selectedNav === 2 &&
          <ServiceChanges serviceChanges={serviceChanges} trains={trains} ref={serviceChangesRef} /> }
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={selectedNav}
            onChange={handleNavChange}
          >
            <BottomNavigationAction label="Departure Times" icon={<AccessTimeFilledIcon />} />
            <BottomNavigationAction label="Delays" icon={<RailwayAlertIcon />} />
            <BottomNavigationAction label="Service Changes" icon={<WarningIcon />} />
          </BottomNavigation>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

export default App;

const baseStyle = {
  width: '56px',
  height: '56px',
  fontSize: '42px',
  lineHeight: '32px',
  textAlign: 'center',
  fontWeight: 'bold',
  fontStyle: 'normal!important',
  color: '#fff',
  marginLeft: 'auto',
  marginRight: 'auto',
}

const bullet = {
  WebkitBorderRadius: '999px',
  MozBorderRadius: '999px',
  borderRadius: '999px',
};

const diamond = {
  transform: 'rotate(45deg)',
  MsTransform: 'rotate(45deg)',
  WebkitTransform: 'rotate(45deg)',
};

const innerDiamond = {
  transform: 'rotate(-45deg)',
  MsTransform: 'rotate(-45deg)',
  WebkitTransform: 'rotate(-45deg)',
  width: '83%',
};

const small = {
  display: 'inline-block',
  width: '28px',
  height: '28px',
  fontSize: '19px',
  lineHeight: '17px',
  marginLeft: 'none',
  margin: '0 2px',
}

const setBaseStyle = (size, name) => {
  const isDiamond = name.endsWith('X');
  if (size) {
    if (isDiamond) {
      return {
        ...baseStyle,
        ...diamond,
        ...small,
      };
    }
    return {
      ...baseStyle,
      ...bullet,
      ...small,
    };
  }

  if (isDiamond) {
    return {
      ...baseStyle,
      ...diamond,
    };
  }

  return {
    ...baseStyle,
    ...bullet,
  };
}

const style = (train, shortenedAlternateName, size) => {
  const { color, text_color, name } = train;
  const styleHash = setBaseStyle(size, name);
  let nameLength = name.length + (shortenedAlternateName?.length || 0);
  styleHash.backgroundColor = `${color}`

  if (text_color) {
    styleHash.color = `${text_color}`;
  }

  if (nameLength > 2) {
    styleHash.letterSpacing = '-.06em';
  }

  return styleHash;
}

const innerStyle = (name, size, shortenedAlternateName) => {
  let nameLength = name.length + (shortenedAlternateName?.length || 0);
  if (nameLength > 2) {
    return { fontSize: '.85em' };
  }
  if (name.endsWith('X')) {
    return innerDiamond;
  }
}

const TrainBullet = (props) => {
  const { id, size, trains } = props;
  const train = trains[id];
  const { name } = train;

  const trainName = name.endsWith('X') ? name[0] : name;
  const alternateName = train.alternate_name
  let shortenedAlternateName = alternateName && alternateName[0];
  let match;
  if (match = alternateName?.match(/^(?<number>[0-9]+)/)) {
    shortenedAlternateName = match.groups.number;
  }

  return (
    <div style={style(train, shortenedAlternateName, size)}>
      <div style={innerStyle(name, size, shortenedAlternateName)}>{trainName}<sup>{shortenedAlternateName}</sup></div>
    </div>
  );
}

export default TrainBullet;
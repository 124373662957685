import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, FormControl, InputLabel, Autocomplete, TextField, DialogActions, Button, Typography } from '@mui/material';

import TrainBullet from './TrainBullet';

const AddStationDialog = (props) => {
  const { selectedStations, stationsList, open, handleClose, handleAddStation, trains } = props;
  const [station, setStation] = useState(null);

  const handleChange = (event, value) => {
    setStation(value?.id);
  };

  const handleAddButton = () => {
    handleAddStation(station);
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Station</DialogTitle>
      <DialogContent sx={{ minWidth: { md: 600 }}}>
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1 }}>
            <Autocomplete
              id="station"
              sx={{ width: 500 }}
              options={stationsList.filter((s) => !selectedStations.includes(s.id))}
              autoHighlight
              autoSelect
              onChange={handleChange}
              getOptionLabel={(option) => (option.secondary_name ? `${option.name.replace(/ - /g, "–")} (${option.secondary_name})` : option.name.replace(/ - /g, "–"))}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  { option.name.replace(/ - /g, "–") }
                  {
                    option.secondary_name &&
                    <Typography variant="caption" sx={{ml: 1}}>
                      { option.secondary_name }
                    </Typography>
                  }
                  <Box sx={{ml: 1}}>
                    {
                      Object.keys(option.routes).map((trainId) => (
                        <TrainBullet id={trainId} key={trainId} trains={trains} size='small' />
                      ))
                    }
                    </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a station"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddButton} disabled={!station}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddStationDialog;
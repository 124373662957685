import { Container, Grow, Box, Paper, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import TrainBullet from './TrainBullet';

import { replaceTrainBulletsInParagraphs } from '../utils';

const ServiceChangesContent = (props) => {
  const { trains, trainId, serviceChanges } = props;
  return (
    <Container maxWidth="xl">
      <Grow in>
      <Box sx={{ display: 'flex', flexGrow: 1, flexAlign: 'flex-start'}}>
        <Paper elevation={10} sx={{ width: '100%', my: 2, display: 'inline-block' }}>
          <List>
            <ListItem divider key={trainId}>
              <ListItemAvatar sx={{ mr: 2 }}>
                <TrainBullet id={trainId} trains={trains} />
              </ListItemAvatar>
              <ListItemText>
                {
                  replaceTrainBulletsInParagraphs(trains, serviceChanges)
                }
              </ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Box>
      </Grow>
    </Container>
  );
}

export default ServiceChangesContent;
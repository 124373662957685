import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { AppBar, Container, Toolbar, Box, Button, Grow, Paper, List, ListItem, ListItemText } from '@mui/material';

import ServiceChangesContent from './ServiceChangesContent';
import TrainBullet from './TrainBullet';

const ServiceChanges = forwardRef((props, ref) => {
  const { serviceChanges, trains } = props;
  const [selectedTrainId, setSelectedTrainId] = useState(null);

  useEffect(() =>{
    if (serviceChanges && (selectedTrainId === null || !Object.keys(serviceChanges).includes(selectedTrainId))) {
      setSelectedTrainId(Object.keys(serviceChanges)[0]);
    }
  }, [serviceChanges])

  const handleTrainSelect = (event) => {
    setSelectedTrainId(event.currentTarget.dataset.trainid);
  };

  useImperativeHandle(ref, () => ({
    goNext() {
      const serviceChangesKeys = Object.keys(serviceChanges);
      if (serviceChangesKeys.length < 1) {
        return false;
      }
      const i = serviceChangesKeys.indexOf(selectedTrainId);
      if (i === serviceChangesKeys.length - 1) {
        return false;
      }
      setSelectedTrainId(serviceChangesKeys[i + 1]);
      return true;
    }
  }));


  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {
                Object.keys(serviceChanges).map((trainId) => (
                  <Button
                    key={trainId}
                    sx={{ my: 2, opacity: selectedTrainId === trainId ? '100%' : '30%', display: 'block' }}
                    data-trainid={trainId}
                    onClick={handleTrainSelect}
                  >
                  <TrainBullet id={trainId} trains={trains} size='small' />
                  </Button>
                ))
              }
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl">
        { selectedTrainId && trains && serviceChanges[selectedTrainId] &&
          <ServiceChangesContent trainId={selectedTrainId} trains={trains} serviceChanges={serviceChanges[selectedTrainId]} />
        }
        { !selectedTrainId &&
          <Container>
            <Grow in>
              <Box sx={{ display: 'flex', flexGrow: 1, flexAlign: 'flex-start'}}>
                <Paper elevation={10} sx={{ width: '100%', my: 2, display: 'inline-block' }}>
                  <List>
                    <ListItem>
                      <ListItemText>
                        No service changes detected, hooray!
                      </ListItemText>
                    </ListItem>
                  </List>
                </Paper>
              </Box>
            </Grow>
          </Container>
        }
      </Container>
    </>
  )
});

export default ServiceChanges;
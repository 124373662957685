import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Container, Toolbar, AppBar, Box, Button, Typography, SpeedDial, SpeedDialIcon, SpeedDialAction, List, ListItem, ListItemText, Paper, Grow } from '@mui/material';

import AddStationDialog from './AddStationDialog';
import DeleteStationDialog from './DeleteStationDialog';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import DepartureBoard from './DepartureBoard';

const DepartureTimes = forwardRef((props, ref) => {
  const { selectedStations, stations, stationsList, trains, handleAddStation, handleRemoveStation } = props;
  const [selectedStation, setSelectedStation] = useState(selectedStations[0]);
  const [isAddStationDialogOpen, setIsAddStationDialogOpen] = useState(false);
  const [isDeleteStationDialogOpen, setIsDeleteStationDialogOpen] = useState(false);

  const handleStationSelect = (event) => {
    setSelectedStation(event.currentTarget.dataset.stationid);
  };

  const handleOpenAddStationDialog = () => {
    setIsAddStationDialogOpen(true);
  }

  const handleCloseAddStationDialog = () => {
    setIsAddStationDialogOpen(false);
  }

  const handleOpenDeleteStationDialog = () => {
    setIsDeleteStationDialogOpen(true);
  }

  const handleCloseDeleteStationDialog = () => {
    setIsDeleteStationDialogOpen(false);
  }

  useEffect(() => {
    if (selectedStation && selectedStations.length > 0 && selectedStations.indexOf(selectedStation) < 0) {
      setSelectedStation(selectedStations[0]);
    } else if (selectedStations.length < 1) {
      setSelectedStation(null);
      setIsDeleteStationDialogOpen(false);
    } else if (!selectedStation) {
      setSelectedStation(selectedStations[0]);
    }
  }, [selectedStations, selectedStations[0]]);

  useImperativeHandle(ref, () => ({
    goNext() {
      if (isAddStationDialogOpen || isDeleteStationDialogOpen) {
        return true;
      }
      if (selectedStations.length < 1) {
        return false;
      }
      const i = selectedStations.indexOf(selectedStation);
      if (i === selectedStations.length - 1) {
        return false;
      }
      setSelectedStation(selectedStations[i + 1]);
      return true;
    }
  }));

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {
                Object.keys(stations).length > 0 && selectedStations.map((station) => (
                  <Button
                    key={station}
                    sx={{ my: 2, color: selectedStation === station ? 'white' : 'gray', display: 'block' }}
                    data-stationid={station}
                    onClick={handleStationSelect}
                  >
                  { stations[station].name.replace(/ - /g, "–") }
                  { stations[station].secondary_name &&
                    <Typography variant="caption" display='block'>
                      { stations[station].secondary_name }
                    </Typography>
                  }
                  </Button>
                ))
              }
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl">
        { selectedStation && <DepartureBoard station={selectedStation} stations={stations} trains={trains} /> }
        { !selectedStation &&
          <Container>
            <Grow in>
              <Box sx={{ display: 'flex', flexGrow: 1, flexAlign: 'flex-start'}}>
                <Paper elevation={10} sx={{ width: '100%', my: 2, display: 'inline-block' }}>
                  <List>
                    <ListItem>
                      <ListItemText>
                        Add a station to start seeing departure times.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Paper>
              </Box>
            </Grow>
          </Container>
        }
        <SpeedDial
          ariaLabel="Edit Stations"
          sx={{ position: 'absolute', bottom: 72, right: 16 }}
          icon={<SpeedDialIcon />}>
          <SpeedDialAction disabled={selectedStations.length < 1} icon={<DeleteIcon />} name='Delete' tooltipTitle='Delete' onClick={handleOpenDeleteStationDialog} />
          <SpeedDialAction icon={<AddIcon />} name='Add' tooltipTitle='Add' onClick={handleOpenAddStationDialog} />
        </SpeedDial>
        <AddStationDialog selectedStations={selectedStations} stationsList={stationsList} trains={trains} open={isAddStationDialogOpen} handleClose={handleCloseAddStationDialog} handleAddStation={handleAddStation} />
        <DeleteStationDialog selectedStations={selectedStations} stations={stations} trains={trains} open={isDeleteStationDialogOpen} handleClose={handleCloseDeleteStationDialog} handleRemoveStation={handleRemoveStation} />
      </Container>
    </>
  );
});

export default DepartureTimes;
import { useState, useEffect } from 'react';
import { Container, Box, Paper, List, ListItem, ListItemAvatar, ListItemText, Grow } from '@mui/material';
import MoodBadIcon from '@mui/icons-material/MoodBad';

import TrainBullet from './TrainBullet';
import { useInterval } from '../utils';

const stopUrlBase = 'https://goodservice.io/api/stops/';

const parseTrips = (trips) => {
  const upcomingRoutes = [];

  if (!trips) {
    return [];
  }

  trips.forEach((trip) => {
    const matchingRoute = upcomingRoutes.find((ur) => ur.routeId === trip.route_id && ur.destination === trip.destination_stop);

    if (matchingRoute) {
      if (matchingRoute.times.length === 1) {
        matchingRoute.times.push(trip.estimated_current_stop_arrival_time);
      }
    } else {
      upcomingRoutes.push({
        routeId: trip.route_id,
        destination: trip.destination_stop,
        times: [trip.estimated_current_stop_arrival_time]
      });
    }
  });

  return upcomingRoutes;
}

const DepartureBoard = (props) => {
  const { station, stations, trains } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [northDepartureTimes, setNorthDepartureTimes] = useState([]);
  const [southDepartureTimes, setSouthDepartureTimes] = useState([]);
  const currentTime = Date.now() / 1000;

  const updateData = () => {
    fetch(stopUrlBase + station)
    .then(response => response.json())
    .then(data => {
      setNorthDepartureTimes(parseTrips(data.upcoming_trips.north));
      setSouthDepartureTimes(parseTrips(data.upcoming_trips.south));
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    updateData();
  }, [station]);
  useInterval(updateData, 15000);

  return (
    <Container>
      <Grow
        in={!isLoading}
      >
        <Box sx={{ display: 'flex', flexGrow: 1, flexAlign: 'flex-start'}}>
          {
            (northDepartureTimes.length > 0 || southDepartureTimes.length > 0) &&
            [northDepartureTimes, southDepartureTimes].map((departureTimes, index) => (
              <Paper elevation={10} sx={{ width: '46%', m: '1em', display: 'inline-block' }} key={index}>
              <List>
                {
                  departureTimes.map((dt) => {
                    const firstEta = (dt.times[0] - currentTime) / 60
                    let text;
                    if (firstEta < 1) {
                      text = 'due';
                    } else {
                      text = `${Math.round(firstEta)}`;
                    }

                    if ((dt.times.length === 1) && (text !== 'due')) {
                      text += ' min';
                    } else if (dt.times.length > 1) {
                      const secondEta = (dt.times[1] - currentTime) / 60
                      if (secondEta < 1) {
                        text += ', due';
                      } else {
                        text += `, ${Math.round(secondEta)} min`;
                      }
                    }

                    const stationNameArray = stations[dt.destination].name.split(' - ');

                    const primaryStationName = stationNameArray[0];
                    let secondaryStationName = '';
                    if (stationNameArray.length > 1) {
                      secondaryStationName = stationNameArray.slice(1).join('–');
                    } else {
                      secondaryStationName = stations[dt.destination].secondary_name?.replace(/\([^()]*\)/g, '');
                      if (secondaryStationName?.indexOf('Line') > -1) {
                        secondaryStationName = null;
                      }
                    }

                    return (
                      <ListItem divider key={`${dt.routeId}-${dt.destination}`}>
                        <ListItemAvatar sx={{ mr: 1 }}>
                          <TrainBullet id={dt.routeId} trains={trains} />
                        </ListItemAvatar>
                        <ListItemText primary={primaryStationName} secondary={secondaryStationName || '\xa0'} />
                        <span>{ text} </span>
                      </ListItem>
                    )
                  })
                }
              </List>
              </Paper>
            ))
          }
          {
            northDepartureTimes.length === 0 && southDepartureTimes.length === 0 &&
            <Paper elevation={10} sx={{ width: '100%', my: 2, display: 'inline-block' }}>
              <List>
                <ListItem>
                  <ListItemText>
                    There are no upcoming departures. <MoodBadIcon />
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          }
        </Box>
      </Grow>
    </Container>
  )
}

export default DepartureBoard;